export default [
  {
    id: 'alex-manga',
    name: 'Alex Manga',
  },
  {
    id: 'ana-del-castillo',
    name: 'Ana del Castillo',
  },
  {
    id: 'bola-corrales',
    name: 'Bola Corrales ',
  },
  {
    id: 'brenda-arrieta',
    name: 'Brenda Arrieta',
  },
  {
    id: 'camilo-carvajal',
    name: 'Camilo Carvajal',
  },
  {
    id: 'campillo',
    name: 'Campillo',
  },
  {
    id: 'carlos-parra',
    name: 'Carlos Parra',
  },
  {
    id: 'carlos-rueda',
    name: 'Carlos Rueda',
  },
  {
    id: 'carlos-zedan',
    name: 'Carlos Zedan ',
  },
  {
    id: 'carolina-celedon',
    name: 'Carolina Celedón',
  },
  {
    id: 'cesar-del-valle',
    name: 'Cesar del Valle',
  },
  {
    id: 'chide-garcia',
    name: 'Chide García',
  },
  {
    id: 'churo-diaz',
    name: 'Churo Díaz',
  },
  {
    id: 'cristian-better',
    name: 'Cristian Better',
  },
  {
    id: 'dani-maestre',
    name: 'Dani Maestre',
  },
  {
    id: 'daniel-maestre',
    name: 'Daniel Maestre',
  },
  {
    id: 'danny-orozco',
    name: 'Danny Orozco',
  },
  {
    id: 'david-chaar',
    name: 'David Chaar',
  },
  {
    id: 'delay-magdaniel',
    name: 'Delay Magdaniel',
  },
  {
    id: 'diego-daza',
    name: 'Diego Daza',
  },
  {
    id: 'diomedes-de-jesus',
    name: 'Diomedes de Jesus',
  },
  {
    id: 'duban-bayona',
    name: 'Dubán Bayona',
  },
  {
    id: 'el-puma-del-vallenato',
    name: 'El Puma del Vallenato',
  },
  {
    id: 'el-vallero-diaz',
    name: 'El Vallero Díaz',
  },
  {
    id: 'elder-dayan-diaz',
    name: 'Elder Dayán Díaz',
  },
  {
    id: 'elias-mendoza',
    name: 'Elías Mendoza',
  },
  {
    id: 'elkin-uribe',
    name: 'Elkin Uribe',
  },
  {
    id: 'emmanuel-fernandez',
    name: 'Emmanuel Fernandez',
  },
  {
    id: 'fabian-corrales',
    name: 'Fabián Corrales',
  },
  {
    id: 'fabio-quiroz',
    name: 'Fabio Quiroz',
  },
  {
    id: 'farid-leonardo-diaz',
    name: 'Farid Leonardo Díaz',
  },
  {
    id: 'farid-ortiz',
    name: 'Farid Ortiz',
  },
  {
    id: 'fello-zabaleta',
    name: 'Fello Zabaleta',
  },
  {
    id: 'fernando-rangel',
    name: 'Fernando Rangel',
  },
  {
    id: 'franco-arguelles',
    name: 'Franco Arguelles',
  },
  {
    id: 'gustavo-gutierrez',
    name: 'Gustavo Gutierrez',
  },
  {
    id: 'harold-martinez',
    name: 'Harold Martínez',
  },
  {
    id: 'harold-ortega',
    name: 'Harold Ortega',
  },
  {
    id: 'hebert-vargas',
    name: 'Hebert Vargas',
  },
  {
    id: 'hugues-martinez',
    name: 'Hugues Martinez',
  },
  {
    id: 'imera-mejia',
    name: 'Imera Mejía',
  },
  {
    id: 'ivan-ballesteros',
    name: 'Iván Ballesteros',
  },
  {
    id: 'ivan-lopez',
    name: 'Iván Lopez',
  },
  {
    id: 'ivan-villazon',
    name: 'Iván Villazón',
  },
  {
    id: 'javier-matta',
    name: 'Javier Matta',
  },
  {
    id: 'jean-carlos-centeno',
    name: 'Jean Carlos Centeno',
  },
  {
    id: 'jhon-mindiola',
    name: 'Jhon Mindiola',
  },
  {
    id: 'jimmy-zambrano',
    name: 'Jimmy Zambrano',
  },
  {
    id: 'jk-padilla',
    name: 'Jk Padilla',
  },
  {
    id: 'jorge-celedon',
    name: 'Jorge Celedón',
  },
  {
    id: 'jorge-clavijo',
    name: 'Jorge Clavijo',
  },
  {
    id: 'jorge-mario-pena',
    name: 'Jorge Mario Peña',
  },
  {
    id: 'jose-dario-orozco',
    name: 'Jose Dario Orozco',
  },
  {
    id: 'jose-sanchez',
    name: 'José Sanchez',
  },
  {
    id: 'juan-maestre',
    name: 'Juan Maestre',
  },
  {
    id: 'juancho-de-la-espriella',
    name: 'Juancho de la Espriella',
  },
  {
    id: 'juanse-rivero',
    name: 'Juanse Rivero',
  },
  {
    id: 'juanse-solano',
    name: 'Juanse Solano',
  },
  {
    id: 'julian-rojas',
    name: 'Julián Rojas',
  },
  {
    id: 'junior-saavedra',
    name: 'Junior Saavedra',
  },
  {
    id: 'kanner-morales',
    name: 'Kanner Morales',
  },
  {
    id: 'karen-lizarazo',
    name: 'Karen Lizarazo',
  },
  {
    id: 'katrinalieth-morales ',
    name: 'Katrinalieth Morales ',
  },
  {
    id: 'kenel-yancy',
    name: 'Kenel Yancy',
  },
  {
    id: 'keyner-morales',
    name: 'Keyner Morales',
  },
  {
    id: 'laura-hamburger',
    name: 'Laura Hamburger',
  },
  {
    id: 'laura-zuleta',
    name: 'Laura Zuleta',
  },
  {
    id: 'lucas-dangond',
    name: 'Lucas Dangond',
  },
  {
    id: 'luifer-cuello',
    name: 'Luifer Cuello',
  },
  {
    id: 'luifer-mercado',
    name: 'Luifer Mercado',
  },
  {
    id: 'luis-angel-diaz',
    name: 'Luis Angel Díaz',
  },
  {
    id: 'luisda-solano',
    name: 'Luisda Solano',
  },
  {
    id: 'luisra-solano',
    name: 'Luisra Solano',
  },
  {
    id: 'marco-penaranda',
    name: 'Marco Peñaranda',
  },
  {
    id: 'margarita-doria',
    name: 'Margarita Doria',
  },
  {
    id: 'mario-fuscaldo',
    name: 'Mario Fuscaldo',
  },
  {
    id: 'marlon-rangel',
    name: 'Marlon Rangel',
  },
  {
    id: 'masilena-ovalle',
    name: 'Masilena Ovalle',
  },
  {
    id: 'michel-torres',
    name: 'Michel Torres',
  },
  {
    id: 'miguel-morales',
    name: 'Miguel Morales',
  },
  {
    id: 'mono-zabaleta',
    name: 'Mono Zabaleta',
  },
  {
    id: 'nano-molina',
    name: 'Nano Molina',
  },
  {
    id: 'natalia-curvelo',
    name: 'Natalia Curvelo',
  },
  {
    id: 'nelson-velasquez',
    name: 'Nelson Velasquez ',
  },
  {
    id: 'nemecio-gomez',
    name: 'Nemecio Gomez',
  },
  {
    id: 'neno-beleno',
    name: 'Neno Beleño',
  },
  {
    id: 'nico-pineda',
    name: 'Nico Pineda',
  },
  {
    id: 'omar-geles',
    name: 'Omar Geles',
  },
  {
    id: 'omar-hernandez',
    name: 'Omar Hernandez',
  },
  {
    id: 'orlando-linan',
    name: 'Orlando Liñan',
  },
  {
    id: 'oscar-gamarra',
    name: 'Oscar Gamarra',
  },
  {
    id: 'pablo-garcia',
    name: 'Pablo García',
  },
  {
    id: 'peter-manjarres',
    name: 'Peter Manjarrés',
  },
  {
    id: 'pipe-pelaez',
    name: 'Pipe Pelaez',
  },
  {
    id: 'pitokko',
    name: 'Pitokko',
  },
  {
    id: 'poncho-zuleta',
    name: 'Poncho Zuleta',
  },
  {
    id: 'rafa-perez',
    name: 'Rafa Perez',
  },
  {
    id: 'rafael-de-jesus-diaz',
    name: 'Rafael De Jesús Diaz',
  },
  {
    id: 'rafael-santos',
    name: 'Rafael Santos',
  },
  {
    id: 'rk-kammerer',
    name: 'RK Kammerer',
  },
  {
    id: 'rolando-ochoa',
    name: 'Rolando Ochoa',
  },
  {
    id: 'roncallo',
    name: 'Roncallo',
  },
  {
    id: 'ruben-lanao',
    name: 'Ruben Lanao',
  },
  {
    id: 'samuel-morales',
    name: 'Samuel Morales',
  },
  {
    id: 'saul-lallemand',
    name: 'Saúl Lallemand',
  },
  {
    id: 'sergio-luis-rodriguez',
    name: 'Sergio Luis Rodriguez ',
  },
  {
    id: 'silvestre-dangond',
    name: 'Silvestre Dangond',
  },
  {
    id: 'simon-figueroa',
    name: 'Simón Figueroa',
  },
  {
    id: 'wilfran-castillo',
    name: 'Wilfran Castillo',
  },
  {
    id: 'yader-romero',
    name: 'Yader Romero',
  },
  {
    id: 'yorjan-herrera',
    name: 'Yorjan Herrera',
  },
];
