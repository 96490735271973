import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import Link from 'next/link';
import Image from 'next/image';
import artists from '../../data/artists_carousel';

const CoverflowCarousel = () => {
  return (
    <>
      <div className='relative'>
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            100: {
              // width: 640,
              slidesPerView: 2,
              coverflowEffect: {
                rotate: 30,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              },
            },
            575: {
              // width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            992: {
              // width: 768,
              slidesPerView: 5,
            },
          }}
          effect={'coverflow'}
          grabCursor={false}
          centeredSlides={true}
          slidesPerView={5}
          loop={true}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          navigation={{
            nextEl: '.swiper-button-next-4',
            prevEl: '.swiper-button-prev-4',
          }}
          className='swiper coverflow-slider !py-5'
        >
          {artists.map((item) => {
            const { id, image, name } = item;
            return (
              <SwiperSlide key={id} className='flex justify-center'>
                <article>
                  <div className='block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700 max-w-xs'>
                    <figure className='relative flex justify-center'>
                      <Link href={'/artista/' + id}>
                        <Image
                          src={image}
                          alt={name}
                          className='swiper-lazy h-[430px] w-full object-cover'
                          height='430'
                          width='379'
                        />
                      </Link>
                    </figure>
                    <div className='p-2 flex justify-center'>
                      <Link href={'/artista/' + id}>
                        <button className='font-bold text-jacarta-700 text-center rounded-lg'>
                          {name}
                        </button>
                      </Link>
                    </div>
                  </div>
                </article>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className='swiper-button-prev-4 group absolute top-1/2 left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='fill-jacarta-700 group-hover:fill-accent'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z' />
          </svg>
        </div>
        <div className='swiper-button-next-4 group absolute top-1/2 right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='fill-jacarta-700 group-hover:fill-accent'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' />
          </svg>
        </div>
      </div>
    </>
  );
};

export default CoverflowCarousel;
