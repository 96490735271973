export default [
  {
    id: 'silvestre-dangond',
    name: 'Silvestre Dangond',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fsilvestre-dangond%2Fimages%2FS9fFEK8DH5JOq48Q4Y7p-profile.png?alt=media&token=2d44d2ee-7b33-4233-8dae-78bf380b892e',
  },
  {
    id: 'churo-diaz',
    name: 'Churo Díaz',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fchuro-diaz%2Fimages%2F13NG9aEpSdpFiQK1GNko-profile.png?alt=media&token=3515c092-e8b8-4fa7-bdb5-2364169855b9',
  },
  {
    id: 'diego-daza',
    name: 'Diego Daza',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fdiego-daza%2Fimages%2FMPpRNOo6h3oxOo70xnjj-profile.png?alt=media&token=4afda808-710c-488a-accf-38eb43506a8b',
  },
  {
    id: 'luifer-cuello',
    name: 'Luifer Cuello',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fluifer-cuello%2Fimages%2F3fX32buxuk2TDLPHrCaK-profile.png?alt=media&token=ed5f0232-5ba0-4fe2-b353-d6b1896c3773',
  },
  {
    id: 'ana-del-castillo',
    name: 'Ana del Castillo',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fana-del-castillo%2Fimages%2FrLjKbeLSYi6LCeqF5ETm-profile.png?alt=media&token=26c2247b-9982-4451-829e-131559552761',
  },
  {
    id: 'oscar-gamarra',
    name: 'Oscar Gamarra',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Foscar-gamarra%2Fimages%2FcVflkzxUed395jWKKRZR-profile.png?alt=media&token=3bd6496c-19cd-415b-a0e6-053229eb5087',
  },
  {
    id: 'margarita-doria',
    name: 'Margarita Doria',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fmargarita-doria%2Fimages%2FDIqLn7gFX3AL5E2PgLvg-profile.png?alt=media&token=a1a3aaf5-b250-4ad1-a76b-7a4337df48fa',
  },
  {
    id: 'poncho-zuleta',
    name: 'Poncho Zuleta',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fponcho-zuleta%2Fimages%2FQPNFUzlwoVJbKxpWrX0G-profile.png?alt=media&token=bd914b01-1aab-497e-a6ed-5e990c0157eb',
  },
  {
    id: 'natalia-curvelo',
    name: 'Natalia Curvelo',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fnatalia-curvelo%2Fimages%2FNAsDjmpYqR3DrZjOtMpp-profile.png?alt=media&token=30d167cc-0a7a-4cc8-8dca-38c1ba29c233',
  },
  {
    id: 'rafa-perez',
    name: 'Rafa Perez',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Frafa-perez%2Fimages%2FQDUDO32oX6poRZbn7FB7-profile.png?alt=media&token=e8038b00-c2bf-4b7d-9996-fdd037d28931',
  },
  {
    id: 'karen-lizarazo',
    name: 'Karen Lizarazo',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fkaren-lizarazo%2Fimages%2FsC4HQNKcdP641wUmtwmu-profile.png?alt=media&token=19b4f647-5706-444b-811a-468e3943fd42',
  },
  {
    id: 'mono-zabaleta',
    name: 'Mono Zabaleta',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fmono-zabaleta%2Fimages%2F70it00aDG6iKYIeRiSaE-profile.png?alt=media&token=85f9c753-5848-4e18-8fd7-fc1b82e73e08',
  },
  {
    id: 'elder-dayan-diaz',
    name: 'Elder Dayán Díaz',
    image: 'https://i.scdn.co/image/ab67616100005174c10f792c5dcf0cd743b1269c',
  },
  {
    id: 'peter-manjarres',
    name: 'Peter Manjarrés',
    image:
      'https://firebasestorage.googleapis.com/v0/b/dinastia-vallenata.appspot.com/o/artists%2Fpeter-manjarres%2Fimages%2Fev2ZjJ0tiJcvVIqpkGzP-profile.png?alt=media&token=c3b2adbf-5ebf-4b8c-a28c-f7a1d3ebb6f5',
  },
];
